import React from "react"
import PropTypes from "prop-types"
import { setToken, getUserInfo, exitLogin } from "../http"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const defaultUserInfo = {
  name: "",
  email: "",
  company: "",
  notLogin: true,
}

export const UserInfoContext = React.createContext(defaultUserInfo)

class Layout extends React.Component {
  state = {
    userInfo: defaultUserInfo,
  }
  componentDidMount() {
    const { type } = this.props
    const isEn = window.location.href.split("/").includes("en")
    setToken()
    getUserInfo()
      .then(res => {
        if (res.status === 0) {
          this.setState({ userInfo: res.data })
        } else {
          exitLogin()
        }
      })
      .catch(() => exitLogin())
    if (isEn) {
      window.localStorage.setItem("lng", "en")
    } else {
      window.localStorage.setItem("lng", "zh")
    }
    if (type !== "home") {
      console.log(window)
      console.log(window.Particles)
      window.Particles &&
        window.Particles.init(".product-header", {
          particles: {
            number: {
              value: 66,
              density: {
                enable: true,
                value_area: 1420.4657549380909,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 8,
              },
              image: {
                src: "img/github.svg",
                width: 40,
                height: 40,
              },
            },
            opacity: {
              value: 0.3551164387345227,
              random: false,
              anim: {
                enable: false,
                speed: 0.8932849335314796,
                opacity_min: 0.16783216783216784,
                sync: false,
              },
            },
            size: {
              value: 2,
              random: true,
              anim: {
                enable: false,
                speed: 7.192807192807193,
                size_min: 3.196803196803197,
                sync: true,
              },
            },
            line_linked: {
              enable: true,
              distance: 130,
              color: "#ffffff",
              opacity: 0.18939543399174544,
              width: 1.2,
            },
            move: {
              enable: true,
              speed: 3,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: false,
                mode: "repulse",
              },
              onclick: {
                enable: false,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        })
    }
  }

  render() {
    const { userInfo } = this.state
    const { type } = this.props
    return (
      <>
        <Header siteTitle={11} type={type} userInfo={userInfo} />
        <main>
          <UserInfoContext.Provider value={userInfo}>
            {this.props.children}
            {/* {React.children.map(this.props.children, (child) => React.cloneElement(child, { userInfo }))} */}
          </UserInfoContext.Provider>
        </main>
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
}

Layout.defaultProps = {
  type: "home",
}

export default Layout
