import { Link } from "gatsby"
import React, { Component } from "react"
import styled from "styled-components"
import classNames from "classnames"
import { Location } from "@reach/router"
import { Trans, withTranslation } from "react-i18next"
import { exitLogin } from "../http"
import constant from "../constant"
import zh from "../images/cn.svg"
import en from "../images/en.svg"

const lngIcon = {
  zh,
  en,
}

const HeaderContainer = styled.header`
  max-width: 1080px;
  margin: 0 auto;
  /* height: 80px; */
  line-height: 80px;
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  .nav {
    height: 80px;
    font-size: 16px;
    .menu-toggle {
      display: none;
    }
    .nav-menu {
      .nav-item {
        display: flex;
        margin: 0;
        .user-center {
          &:hover {
            .dropdown-content {
              display: unset;
            }
          }
          .dropdown-content {
            display: none;
            bottom: -134px;
          }
          .exit-login {
            display: none;
          }
        }
        .user-center-link {
          padding: 0;
        }
      }
      a {
        position: relative;
        display: inline-block;
        height: 80px;
        box-sizing: border-box;
        padding: 0 20px;
        color: ${props => (props.isHome ? constant.subTitle : "white")};
        &.active::after {
          position: absolute;
          content: "";
          width: calc(100% - 40px);
          height: 2px;
          left: 20px;
          bottom: 20px;
          background: ${props => props.mainColor};
        }
      }
      .user-btn {
        padding: 0;
        line-height: 36px;
        text-align: center;
        height: 36px;
        width: 120px;
        border: 1px solid;
        border-radius: 30px;
        color: ${props => props.mainColor};
        vertical-align: unset;
        margin-left: 20px;
        &:hover {
          color: ${props => props.borderColor};
          background: ${props => props.mainColor};
        }
      }
    }
  }
  .dropdown-container {
    cursor: pointer;
    position: relative;
    padding-left: 46px;
    &:hover {
      .dropdown-content {
        display: unset;
      }
      .arrow::after {
        transform: rotate(135deg);
      }
    }
    .arrow {
      display: inline-block;
      position: relative;
      width: 100%;
      :after {
        transition: all 0.3s;
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        right: -20px;
        top: 50%;
        border-bottom: 1px solid
          ${props => (props.isHome ? "#3B416D" : "white")};
        border-left: 1px solid ${props => (props.isHome ? "#3B416D" : "white")};
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      right: 50%;
      bottom: -90px;
      transform: translateX(50%);
      z-index: 99;
      line-height: normal;
      background-color: #fff;
      color: ${constant.subTitle};
      width: 117px;
      padding: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 6px 24px 0;
      a {
        color: inherit;
        padding: 0;
        height: 35px;
      }
      :before {
        content: "";
        display: block;
        position: absolute;
        height: 15px;
        width: 15px;
        background-color: #fff;
        top: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
        z-index: -1;
      }
      li {
        width: 107px;
        height: 35px;
        line-height: 35px;
        font-size: 12px;
        img {
          margin-right: 10px;
        }
        :hover {
          background-color: #f6f9ff;
        }
      }
    }
  }
  @media (max-width: 985px) {
    .nav_bar {
      direction: rtl;
      .nav {
        order: 1;
        width: 80px;
        direction: initial;
        .menu-toggle {
          display: block;
          width: 80px;
          height: 80px;
          background: 25px 23px / 40px 40px
            url(${props =>
              props.isHome ? constant.menu : constant.menu_white})
            no-repeat;
        }
        #menu-toggle:checked + .nav-menu {
          display: unset;
        }
        .nav-menu {
          display: none;
          position: absolute;
          top: 80px;
          left: 0px;
          right: 0px;
          background: white;
          box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;

          z-index: 1;
          .nav-item {
            display: block;
            line-height: 40px;
            a {
              display: block;
              height: 40px;
              color: ${constant.subTitle};
              &.active::after {
                display: none;
              }
              &.user-btn {
                border-radius: 4px;
                background: ${constant.active};
                color: white;
                margin: 0;
                width: 80px;
                line-height: 30px;
                height: 30px;
              }
            }
            .nav-botton:nth-child(n + 5) {
              display: inline-block;
              margin-left: 20px;
              &:last-child {
                margin-left: 5px;
              }
            }
          }
          .user-center {
            padding-left: 20px !important;
            position: relative;
            .dropdown-content {
              display: none !important;
            }
            .exit-login {
              display: unset;
              position: absolute;
              right: 20px;
              top: 0;
              background: white;
            }
          }
        }
      }
      .dropdown-container {
        vertical-align: top;
        padding-left: 0 !important;
        direction: initial;
      }
    }
  }
`

const isBrowser = () => typeof window !== "undefined"

class Header extends Component {
  static getLng() {
    return isBrowser() && localStorage.getItem("lng")
      ? window.localStorage.getItem("lng")
      : "zh"
  }
  static getLink(to) {
    if (isBrowser()) {
      return `${Header.getLng() === "zh" ? "" : "/en"}${to}`
    }
    return to
  }
  handleChangeLanguage(lng) {
    const isEn = window.location.href.split("/").includes("en")
    if (!isEn && lng === "en") {
      window.localStorage.setItem("lng", "en")
      window.location.href = `/en${window.location.pathname}`
    } else if (isEn && lng === "zh") {
      const set = window.location.pathname.split("/")
      const pathname = window.location.pathname
      // const path = set.pop() || set.pop();
      window.localStorage.setItem("lng", "zh")
      window.location.href = pathname.replace("en/", "")
      // if (path === 'en') {
      //   window.location.href = '/'
      // } else {
      //   window.location.href = `/${path}`;
      // }
    }
  }

  render() {
    const { t, userInfo, type } = this.props
    const tag = userInfo.name || userInfo.email
    const mainColor = type === "home" ? constant.active : "white"
    const borderColor = type === "home" ? "white" : constant.active
    const header = t("header", { returnObjects: true })
    return (
      <Location>
        {({ location }) => (
          <HeaderContainer
            className="d-flex justify-content-between"
            isHome={type === "home"}
            mainColor={mainColor}
            borderColor={borderColor}
          >
            <div className="center">
              <Link className="center" to={Header.getLink("/")}>
                <img
                  src={type === "home" ? constant.logo : constant.logo_other}
                  alt=" "
                />
              </Link>
            </div>
            <div className="nav_bar">
              <div className="nav d-inline-block align-top">
                <label for="menu-toggle" className="menu-toggle" />
                <input className="hide" type="checkbox" id="menu-toggle" />
                <div className="nav-menu">
                  <ul className="nav-item">
                    {(header.nav || []).slice(0, -2).map(item =>
                      item.to !== "/documentation" ? (
                        <li key={item.desc} className="nav-botton">
                          <Link
                            className={classNames({
                              active:
                                location.pathname === Header.getLink(item.to) ||
                                location.pathname.slice(0, -1) ===
                                  Header.getLink(item.to),
                            })}
                            to={Header.getLink(item.to)}
                          >
                            {item.desc}
                          </Link>
                        </li>
                      ) : (
                        <li key={item.desc}>
                          <a href="https://doc.owlscore.com/" target="_blank">
                            {item.desc}
                          </a>
                        </li>
                      )
                    )}
                    {/* {tag ? (
                      <li
                        key={tag}
                        className="dropdown-container user-center pr-5"
                      >
                        <Link
                          to={Header.getLink("/usercenter")}
                          className="arrow user-center-link"
                        >
                          {tag}
                        </Link>
                        <li className="exit-login">
                          <span
                            onClick={() => {
                              exitLogin()
                              window.location.pathname = Header.getLink("/")
                            }}
                          >
                            <img
                              className="user-icon"
                              src={constant.icSet[3]}
                              alt=""
                            />
                            <span>{header.userCenter[3]}</span>
                          </span>
                        </li>
                        <ul className="dropdown-content">
                          {header.userCenter.slice(0, -1).map((nav, idx) => (
                            <li key={nav}>
                              <Link to={Header.getLink(`/usercenter/#${idx}`)}>
                                <img
                                  className="user-icon"
                                  src={constant.icSet[idx]}
                                  alt=""
                                />
                                <span>{nav}</span>
                              </Link>
                            </li>
                          ))}
                          <li>
                            <span
                              onClick={() => {
                                exitLogin()
                                window.location.pathname = Header.getLink("/")
                              }}
                            >
                              <img
                                className="user-icon"
                                src={constant.icSet[3]}
                                alt=""
                              />
                              <span>{header.userCenter[3]}</span>
                            </span>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      (header.nav || []).slice(-2).map(it => (
                        <li key={it.desc} className="nav-botton">
                          <Link className="user-btn" to={Header.getLink(it.to)}>
                            {it.desc}
                          </Link>
                        </li>
                      ))
                    )} */}
                  </ul>
                </div>
              </div>
              <div className="dropdown-container pr-5 d-inline-block align-top">
                <span role="button" className="lng arrow">
                  <img src={lngIcon[t("locale")]} alt="" />
                </span>
                <ul className="dropdown-content">
                  <li>
                    <span
                      className=""
                      onClick={() => this.handleChangeLanguage("zh")}
                    >
                      <img src={lngIcon["zh"]} alt="" width={35} />
                      <span>简体中文</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className=""
                      onClick={() => this.handleChangeLanguage("en")}
                    >
                      <img src={lngIcon["en"]} alt="" width={35} />
                      <span>English</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </HeaderContainer>
        )}
      </Location>
    )
  }
}

export default withTranslation()(Header)
