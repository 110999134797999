import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { withTranslation } from "react-i18next"
import constant from "../constant"
import { getLink } from "../utils"
// import './ballon.css';

const FooterContainer = styled.footer`
  max-width: 1920px;
  //min-width: 1180px;
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    270deg,
    rgba(7, 21, 60, 1) 0%,
    rgba(3, 9, 28, 1) 100%
  );
  .footer-container {
    padding-top: 55px;
    padding-bottom: 35px;
    border-bottom: 1px solid #808ba0;
    width: 74%;
    margin: 0 auto;
    margin-bottom: 13px;
    > div {
      flex: 1 1 256px;
      min-width: 200px;
      margin-left: 10%;
      @media (max-width: 750px) {
        margin-top: 20px;
        &.media-link {
          text-align: left !important;
          .media-list {
            margin: 0;
          }
        }
      }
    }
  }
  h4 {
    font-size: 18px;
    color: #808ba0;
    margin-bottom: 10px;
  }
  a {
    font-size: 16px;
    color: #808ba0;
  }
  p {
    margin: 0 !important;
    color: #808ba0;
    font-size: 16px;
    text-align: left;
  }
  .product {
    //margin-left: 200px;
  }
  .media-list {
    max-width: 157px;
    margin: 0 auto;
    padding: 0 10px;
    .media-item {
      font-size: 0;
      line-height: 0;
      text-indent: -9999px;
      width: 36px;
      height: 36px;
      border: 1px solid #808ba0;
      border-radius: 50%;
      background: center no-repeat;
      a {
        display: block;
        height: 36px;
      }
    }
  }
  .contact {
    font-size: 14px;
  }
  .foot-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .brand {
    //width:106px;
    height: 14px;
    font-size: 14px;
  }
`

function Footer({ t }) {
  const viewText = t("footer", { returnObjects: true })
  return (
    <FooterContainer>
      <div className="footer-container d-flex justify-content-lg-center">
        <div className="contact">
          <h4 style={{ textAlign: "left" }}>{viewText?.contact?.title}</h4>
          <p>{viewText.contact.email}：info@owlscore.com</p>
          {/* <p>{viewText.contact.qq}：874925073</p> */}
        </div>
        {/* <div className="product">
          <h4>{viewText.product.title}</h4>
          <p><Link to="price">{viewText.product.content[0]}</Link></p>
          <p><Link to="/">{viewText.product.content[1]}</Link></p>
        </div>
        <div className="doc">
          <h4>{viewText.price.title}</h4>
          <p><Link to={getLink('/product')}>{viewText.price.content[0]}</Link></p>
          <p><Link to={getLink('/product/#0')}>DOTA2</Link></p>
          <p><Link to={getLink('/product/#1')}>LOL</Link></p>
          <p><Link to={getLink('/product/#2')}>CSGO</Link></p>
          <p><Link to={getLink('/product/#3')}>AOV</Link></p>
        </div> */}
        <div className="media-link text-center">
          <h4 className="footer-title">
            <img src={constant.footLogo} alt="" />
          </h4>
          {/* <ul className="media-list justify-content-between list-group list-group-horizontal group-list">
            <li
              style={{ backgroundImage: `url(${constant.sina})` }}
              className="media-item"
            >
              <a target="_blank" href="https://weibo.com/esportseyes?is_hot=1">
                微博
              </a>
            </li>
            <li
              style={{ backgroundImage: `url(${constant.qq})` }}
              className="media-item"
            >
              <a href="tencent://AddContact/?fromId=50&fromSubId=1&subcmd=all&uin=3456192121">
                QQ
              </a>
            </li>
            <li
              style={{ backgroundImage: `url(${constant.mail})` }}
              className="media-item"
            >
              <a href="mailto:info@owlscore.com">邮箱</a>
            </li>
          </ul> */}
        </div>
      </div>
      {/*<div className="text-center" style={{ color: constant.desc }}>*/}
      {/*  Copyright &#169;2019 owlScore 莫二科技有限公司&nbsp;&nbsp;<a href="http://www.beian.miit.gov.cn" rel="noopener noreferrer" target="_blank">粤ICP备18063141号-2</a>*/}
      {/*</div>*/}
    </FooterContainer>
  )
}

export default withTranslation()(Footer)
